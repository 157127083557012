import { Fragment } from 'react'

import founders_img from '../images/founders/Founders.jpg';
import dennis_img from '../images/founders/Dennis_Tamblyn.jpg';
import danniey_img from '../images/founders/danniey_wright.jpg';
import kelli_img from '../images/founders/kelli_workman.jpg';

const Founders = () => {

    return (
        <Fragment>

    <section className="about py-5 p-3 px-md-5">
        <h1>Our Founders</h1>
  
        <div id="vision" className="row">
  
          <div className="offset-lg-1  col-lg-10 ">
  
  
  
            <h2 className="text-center">Vision</h2>
            <p className="text-justify">Local. Like-minded. Longevity.</p>
            <p className="text-justify">
              SAPAC endeavors to strengthen and unify the Tucson community bringing narratives to life through live
              theatre and music,
              simultaneously engaging seasoned audiences while cultivating a new generation of artists and
              theatregoers.
              Live
              theater has the power to unite communities and foster empathy, a healing outlet for our society. </p>
            <p className="text-justify">
              Eventually transitioning to an equity house, SAPAC will attract a diversity of talent and a greater,
              stronger community of
              artists and audiences. This will create new artistic opportunities for local artists in every aspect of
              theater
              as well as give audiences the chance to widen their scope of what they know. Additionally, giving
              students
              (primary
              and college) access to a professional company and experience that allows them to earn points towards an
              AEA membership.
              We will create opportunities for equitable experience, points, and wages in the arts, which necessitates
              more
              public funding for the performing arts. Increasing arts funding in turn creates a more equitable and
              appealing
              industry. Working in tandem with guest directors, choreographers, other equity houses in the region to
              retain
              talent, foster professionalism, and offer higher quality productions for audiences. </p>
          </div>
  
        </div>
        <div className="row">
          <div className="offset-sm-2 col-sm-8 text-center">
            <img className="img-fluid rounded" src={founders_img} alt="Our Founders" />
          </div>
        </div>
        <div className="row">
          <div className="offset-lg-1 col-lg-10 ">
            <h2 className="text-center">Artistic Team</h2>
            <div className="row">
              <div className="col-sm-3 text-center">
                <img className="img-fluid rounded team-img" src={dennis_img} alt="Dennis Tamblyn" />
              </div>
              <div className="col-sm-9">
                <p className="text-justify">
                  <strong>Dennis Tamblyn, ARTISTIC DIRECTOR</strong> - Dennis is celebrating living in Tucson
                  for the past 22 years and wants nothing more than for musical theater to thrive in this town full
                  of talented performers and enthusiastic audiences. Dennis is proud to be a founding member of
                  SAPAC and has been an artistic leader for over 20 years.</p>
  
  
  
  
  
  
                <a className="btn btn-dark" data-toggle="collapse" href="#dennisBio" role="button" aria-expanded="false"
                  aria-controls="collapseExample">
                  Read More
                </a>
  
                <div className="collapse" id="dennisBio">
                  <div className="card card-body mt-3">
                    <p>
                      A native of Arizona, Dennis has had a very unique
                      and varied history
                      in the performing
                      arts. Born in Scottsdale, Arizona, Dennis had the privilege to be born into an artistic family. He
                      is
                      the
                      youngest of seven children, all of which are musicians and performers including his mother and
                      father.
                      Also,
                      his uncle is actor Russ Tamblyn who is known for his roles as “Riff” in the movie version of “West
                      Side
                      Story”
                      as well as “Giddeon” in “Seven Brides for Seven Brothers”. Russ’ daughter and Dennis’ cousin Amber
                      Tamblyn
                      is also an actress whose credits include Emily Quartermain in “General Hospital”, Joan in the TV
                      series
                      “Joan
                      of Arcadia” and Tibby in the “Sisterhood of the Traveling Pants” movies. </p>
                    <p> Dennis was a boy soprano with the world renowned Phoenix Boys Choir where he was able to perform
                      with such
                      wonderful artists as Shari Lewis, Doc Severinsen and Mr. Mister (who were alumni of the Phoenix Boys
                      Choir).
                      Dennis moved to Tucson in 1997 and pursued a degree in Biochemistry and Molecular Biophysics. After
                      receiving
                      his degree from the University of Arizona, he realized that his true calling was music and he
                      decided to
                      then pursue a 2nd Bachelor’s degree in Voice Performance from the University of Arizona. Primarily
                      focusing
                      on opera, Dennis was able to enjoy many wonderful performances and roles with Arizona Opera, The
                      University
                      of Arizona Opera Theater, Southern Arizona Opera, Alauda and the Tucson Symphony Orchestra. He was
                      also a
                      back-up singer for Barry Manilow during his North America Tour in 2001. </p>
  
  
                    <p>
  
                      Dennis moved to Greeley Colorado to study opera at the University of Northern Colorado and pursuing
                      a Masters in Voice Performance.
                      During this time, Dennis was able to produce, direct and perform in many operatic and musical
                      theater productions.
                      He coordinated a school tour in the Denver Metro area, directed and produced an opera scene program,
                      and
                      starred in the lead tenor roles of every opera during his studies. Dennis also toured Germany,
                      France and
                      Spain during the summer of 2007 with the Germany opera company Opera classNameica where he learned 4
                      roles and
                      3 ensemble parts while also winning the Eduardo Villa Competition where he was able to be a featured
                      soloist
                      with the Frankfurt Symphony Orchestra.
                    </p>
  
  
                    <p>
  
                      In the fall of 2007, Dennis returned to Tucson and continued his career with Arizona Opera as well
                      as the University of Arizona
                      Opera Theater and added experience with Opera New Jersey where he was a young artist. Dennis also
                      was able
                      to direct several opera scenes, assistant direct and direct many operas as well as perform solo
                      works with
                      Tucson Chamber Artists and Southern Arizona Symphony Orchestra.
                    </p>
  
  
                    <p>
  
                      Dennis was also honored to sing the National Anthem in 2009 for President Barack Obama for the
                      “Together We Thrive” event
                      which was a memorial for the tragic shooting that occurred on January 8th, 2011. This event was
                      televised
                      to over 31 million viewers world wide.
                    </p>
  
  
                    <p>
  
                      A long time fan and admirer of Musical Theater, Dennis was able to perform in his first musical in
                      2011 with Arizona OnStage’s
                      production of “Sweeney Todd” where he was Pirelli. This led to many more musical theater productions
                      including
                      Thenardier in “Les Miserables”, Alfie in “A Man of No Importance”, Max Bialystock in “The Producers”
                      as well
                      as Quasimodo in “The Hunchback of Notre Dame”. Dennis is truly a cross over artist and keeps
                      performing musical
                      theater while also continuing to perform opera with Arizona Opera.
  
                    </p>
  
                    <p>
                      Dennis has also been active on the administrative side of the arts. He was co-founder of the opera
                      company, Opera Distillery
                      and was the General Manager of the Gaslight Musical Hall. Dennis is also a Program Coordinator,
                      Senior with
                      the University of Arizona where he does Research Administration. This unique skill set has prepared
                      him for
                      the journey that SAPAC has in store for him.
  
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 text-center">
                <img className="img-fluid rounded team-img" src={danniey_img} alt="Danielle Wright" />
              </div>
              <div className="col-sm-9">
                <p className="text-justify">
                  <strong>Danielle Wright, EXECUTIVE DIRECTOR</strong> A graduate
                  in
                  Theatre Studies from Brigham Young University and recipient of the ACTF National Stage Management
                  Fellowship.
                  She works locally and internationally in theatre and film as a stage manager and assistant director.
                </p>
                <a className="btn btn-dark" data-toggle="collapse" href="#dannieyBio" role="button" aria-expanded="false"
                  aria-controls="collapseExample">
                  Read More
                </a>
                <div className="collapse" id="dannieyBio">
                  <div className="card card-body mt-3">
                    <p>
                      Ever since she portrayed Dorothy in in her 2nd Grade play, Danielle
                      has had a
                      passion for the Arts. She grew up performing with Tucson's Tom Thumb Players and continued to enjoy
                      acting
                      through her middle school and high school years. During her senior year in high school, she began to
                      explore
                      the technical aspects of theater when her teacher, noticing her creative and organizational
                      strengths,
                      recommended
                      that she learn about stage management by shadowing a stage manage at Arizona Theater Company. This
                      opportunity
                      set the course for the rest of Danielle's life. She fell in love with stage management and continued
                      to
                      study
                      theater in college at the University of Arizona and Brigham Young University. A polymath at heart,
                      she
                      explored
                      every aspect of her chosen major that she could, focusing not only on stage management, but scenic
                      design,
                      costume design, and directing. Outside of theater, she studied art history, mathematics, and
                      Japanese.
                    </p>
  
                    <p>
  
                      During her time at BYU, she won the ACTF National Stage Management Fellowship and spent time as an
                      intern at the Eugene O'Neill
                      Theater Center in Waterford, CT. She graduated cum laude in 2007 and was honored to be the
                      convocation
                      speaker
                      for BYU's College of Theatre and Media Arts. Since college, she has worked freelance in theater and
                      film
                      in Utah and Arizona. Recent stage management credits include The Phantom of the Opera, The Magic
                      Flute,
                      The
                      Marriage of Figaro, You're a Good Man, Charlie Brown, and for the past 9 years, she has worked as a
                      stage
                      manager at America's Freedom Festival's Stadium of Fire- the largest stadium fireworks show in the
                      United
                      States. Danielle has also worked overseas as 2nd AD on the documentaries Fires of Faith and Handel's
                      Messiah
                      by Groberg Films. She uses all her free time to add to the artistic landscape of Tucson, through her
                      association
                      and contributions to local theater, as well as the owner of Copper Thimble Makery, a sewing and
                      handcrafting
                      company. When she isn't making theater or sewing, she is at home, dedicating herself to making the
                      lives
                      of her four sons and husband wonderful.
  
  
                    </p>
                  </div>
                </div>
              </div>
            </div>
  
            <div className="row">
              
              <div className="col-sm-3  text-center">
                <img className="img-fluid rounded team-img" src={kelli_img} alt="Kelli Workman" />
              </div>
              <div className="col-sm-9">
                <p className="text-justify">
                  <strong>Kelli Workman, ARTISTIC ADVISOR</strong> - Kelli Workman began performing professionally at 
                  age nine fostering a career and love of musical theatre.  Firm in the belief of knowing it is 
                  not just what we do but how we do it, Kelli hopes SAPAC will impact and enrich the community through excellence. 
                   </p> 
                <a className="btn btn-dark" data-toggle="collapse" href="#kelliBio" role="button" aria-expanded="false"
                  aria-controls="collapseExample">
                  Read More
                </a>
                <div className="collapse" id="kelliBio">
                  <div className="card card-body mt-3">
                      <p>
                      At nine years of age, Kelli Workman began performing professionally
                      in Musical Theater at the TCC Music Hall working for the prestigious Southern Arizona
                      Light Opera Company. At just 11 she was cast as Baby Louise in Gypsy, with Tony
                      award winner Betty Buckley playing Mama Rose. At 17 and with the help of Jeff
                      Haskell, Kelli was chosen to be part of the National Grammy Jazz Choir in New York as
                      one of 20 singers. Throughout her teenage years, Kelli continued working solidly in
                      professional stage productions, film, TV, and of course with Kids Unlimited. Founded by
                      her Mom, Kelli began community service by way of performing with Kids Unlimited at
                      age six. At age 13, she began doubling as the groups’ choreographer and teacher, all
                      while honing her theatrical skills and working professionally. Kelli continues co-
                      directing, teaching, choreographing, and mentoring at Kids Unlimited today.</p>
  
                    <p>She has sailed over 30 countries as a lead singer, dancer, and cabaret artist for Crown and
                      Royal Caribbean Cruises, worked as a singer/dancer for American Music Theatre in
                      Lancaster, PA, as well as choreographing and dancing for The Legendary Superstars at
                      the Santa Ana Star Casino. She also toured 15 cities in China as a featured singer with
                      Artifact Dance Project. Some of her favorite musical theatre credits along the way
                      include I Love You, You’re Perfect Now Change, West Side Story, 42 nd St, Les Miserables,
                      Evita, Godspell, Crazy for You, and Triumph of Love which she received a Mac
                      nomination for under direction of Chach Snook.</p>
                    <p>Kelli is a certified Pilates Instructor for the Fletcher Pilates Company since 2005 and a
                      long-time instructor at Bodyworks Pilates. She has helped create a Pilates Youth Program
                      as well as develop a mentoring program for Fletcher teachers. She also moonlights as a
                      makeup artist for multiple photographers in Tucson. In addition, she holds a Bachelor’s
                      Degree in Psychology from the University of Phoenix with a concentration in Human
                      Services Management.</p>
                    <p>Kelli learned at a very young age a professional skill set for theater. Because of that
                      foundation, she holds dear the importance of work ethic, community, kindness, and
                      respect. These values drive Kelli to work hard and form lasting relationships in all that
                      she does. As a co-director/resident choreographer of Kids Unlimited for the last 15 years,
                      a professional career in theater, and a passion for people, integrity, and excellence, Kelli
                      Workman is thrilled to help bring SAPAC into existence and to the Tucson community.</p>
                  </div>
                </div>
              </div>
  
            </div>
            
  
  
  
          </div>

          </div>
          </section>
  
  
        </Fragment>
    )

}
export default Founders