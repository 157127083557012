import { Fragment } from 'react'
const SupportCorporate = () => {

    return (
        <Fragment>
            <section className="about py-5 p-3 px-md-auto">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">CORPORATE PARTNERSHIPS AND
          SPONSORSHIPS</h2>

                        <div className="py-4 mx-auto">

                            <p>
                                A partnership with SAPAC provides your organization the ability to affiliate with a brand
                                that has become synonymous with quality and innovation. By aligning with SAPAC, you
                                demonstrate a commitment to civic leadership and gain access to unique opportunities
                                for logo exposure, client entertainment and employee engagement. SAPAC will tailor a
                                package to fit the needs and priorities of your company.
          </p>
                            <h4>Corporate Partnerships</h4>
                            <p>
                                Corporate Partners support an entire season of SAPAC work. Corporate Partnerships
                                are extremely flexible, allowing your organization to choose its own benefits and create
                                a package that enhances your business goals while having access to unique options to
                                engage employees and entertain clients. Choose from:
          </p>
                            <ul>
                                <li>A block of complimentary tickets to SAPAC productions</li>
                                <li>Special ticket discounts for your entire company</li>
                                <li>An opportunity to host clients at SAPAC’s Annual Broadway Gala</li>
                                <li>An opportunity to host private events with behind-the-scenes access</li>
                                <li>Recognition in play programs, lobby signage, and SAPAC’s website</li>
                                <li> An opportunity to host a corporate night at the theater with dedicated signage</li>

                            </ul>

                            <h4>Corporate Sponsorships</h4>
                            <p>
                                Corporate Sponsors provide support for individual SAPAC productions. Corporate
                                Sponsors receive featured recognition on all materials for the individual production
                                they’ve selected to support including recognition on subscription series materials, lobby
                                signage, SAPAC’s website, and our electronic email and social media notices.
          </p>
                            <p>
                                Opportunities to entertain include complimentary tickets, the opportunity to host private
                                events, and invitations to exclusive SAPAC events and celebrations.
          </p>
                            <p>Please contact info.sapac@gmail.com or call <a href="tel:+5202610915">520-261-0915</a>  to partner with us.</p>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )

}
export default SupportCorporate